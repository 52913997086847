import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';
import { LOGIN_USER } from '../graphql/mutation'
import { client } from '../main.js'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  // reducer: (state) => ({ customerFilters: state.customerFilters, orderFilters: state.orderFilters,
  //   customerSearch: state.customerSearch, customerId:state.customerId }),
  // filter: (mutation) => mutation.type == 'addNavItem'
});

function initialState () {
  return {
    appName: 'Partner Dashboard',
    logo: require('../assets/images/favicon.png'),
    img: require('../assets/images/logo-dark.png'),
    dark: false,
    namespaced: true,
    token: null,
    user:{},
    authStatus: false,
    errors: [],
    customerFilters:{
      country: null,
      referrerType: null,
      filter_date_lte:null,
      filter_date_gte:null,
      isStaff:null,
      isActive:true,
      isCreatedByStaff: false,
      emailVerified: false,
      createdFrom: null,
      categories: [],
      area: null,
      carMake: null,
      carModel: null,      
    },
    orderFilters: {
      sort_by:'SCHEDULED_AT - DESC',
      status: null,
      filter_date_lte:null,
      filter_date_gte:null,
      paymentMethods:[],
      categories:[],
      serviceAreas:[],
      currency:null,
      isWalkIn:null,
      isInvoiced:null,
      isQuoted: null,
      isQuoteRequired: null,
      isFullyQuoted: null,
      workshop: null,
      assignedToLocal:null,
      jobAssignedToLocal:null,
      assignmentStatus:null,
      customFilterDownValue:null,
      orderSource:null,
      tags:[],
      jobAssignmentStatus:null,
      filter_date_lte_created:null,
      filter_date_gte_created:null,
      filter_date_lte_confirmed:null,
      filter_date_gte_confirmed:null,
      isConfirmed: null,
      hasApprovedQuotation: null,
      confirmedByLocal: null,
      isAssignedToMe:null,
      isInspected:null,
      // orderSearch:null
    },
    workshopOrderFilters: {
      sort_by:'SCHEDULED_AT - DESC',
      filter_date_lte:null,
      filter_date_gte:null,
      filter_date_lte_quoted:null,
      filter_date_gte_quoted:null,
      status: null,
      isQuoted: null,
      isQuoteRequired: null,
      isFullyQuoted: null,
      assignedTo: null,
      workshop: null,
      isPendingAcceptance: null,
      // orderSearch:null
    },
    washSubscriptionFilters:{
      user : null,
      status: null,
      weekDay: null,
      building: null,
      package: null,
      currency: null,
      filter_date_gte_created: null,
      filter_date_lte_created: null,
      filter_date_gte:null,
      filter_date_lte:null,
    },
    scheduleViewsFilters:{
      sort_by:'VIEWED_AT - DESC',
      filter_date_lte:null,
      filter_date_gte:null,
      categories:[],
      serviceAreas:[],
      country:null,
      status:null,
      filter_time_lte:null,
      filter_time_gte:null,
      isConverted: null,
      isNotified: null,
    },
    customerSearch:null,
    customerId:null,
    serviceEnqFilters:{
      sort_by:'ENQUIRED_AT - DESC',
      filter_date_lte: null,
      filter_date_gte: null,
      categories: [],
      serviceAreas: [],
      country: null,
      status: null,
      area:null,
    },
    packageViewFilters: {
      filter_date_lte: null,
      filter_date_gte: null,
      filter_converted_date_lte: null,
      filter_converted_date_gte: null,
      building: null,
      status: null,
      area:null,
    },
    packageTypeViewsFilters:{
      sort_by:'VIEWED_AT - DESC',
      filter_date_lte:null,
      filter_date_gte:null,
      packageCategory:null,
      status:null,
    },
    addOnServiceFilters: {
      currency: null,
      filter_created_gte: null,
      filter_created_lte: null,
      filter_updated_gte: null,
      filter_updated_lte: null,
      filter_used_gte: null,
      filter_used_lte: null,
      filter_package_type: null,
      isPaid: null,
      isUsed: null,
      sort_by: "CREATED_AT - DESC",
    },
    serviceAreaViewsFilters: {
      sort_by: "VIEWED_AT - DESC",
      gte: null,
      lte: null,
      // area: null,
      categories: [],
      serviceAreas: [],
      country: null,
      status: null,
      isConverted: null,
    },
    addOnServiceSearch:null,
    jobsFilters:{
      sort_by:null,
      status: null,
      filter_date_lte:null,
      filter_date_gte:null,
      assignedToLocal:null,
      isStarted:null,
      isActive:true,
      isQuoted:null,
      categories:[],
      jobTypes:[],
      jobStatusData:[],
    },
    jobTimelineFilters:{
      assignedToLocal:null,
      categories:[],
      jobTypes:[],
      jobStatusData:[],
      country:null,
      serviceArea:null,
      workshops:[],
      filter_date_gte:null,
    },
  }
}

export default new Vuex.Store({
  state: initialState,
  plugins: [vuexLocal.plugin],
  mutations: {
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo-v2.png')
        state.img = require('../assets/images/logo-dark.png')
      } else {
        state.logo = require('../assets/images/logo-v2.png')
        state.img = require('../assets/images/logo-v2.png')
      }
    },
    customerFilterCommit (state, payload) {
      state.customerFilters = payload
    },
    orderFilterCommit (state, payload) {
      state.orderFilters = payload
    },
    searchCommit (state, payload) {
      state.customerSearch = payload
    },
    createOrderCommit(state, payload){
      state.customerId = payload
    },
    workshopOrderFilterCommit (state, payload) {
      state.workshopOrderFilters = payload
    },
    washSubscriptionFilterCommit(state, payload){
      state.washSubscriptionFilters = payload
    },
    searchWashCommit(state, payload){
      state.washSubscriptionSearch = payload
    },
    scheduleViewsFilterCommit (state, payload) {
      state.scheduleViewsFilters = payload
    },
    serviceEnqFilterCommit (state, payload) {
      state.serviceEnqFilters = payload
    },
    packageViewFilterCommit (state, payload) {
      state.packageViewFilters = payload
    },
    packageTypeViewsFilterCommit (state, payload) {
      state.packageTypeViewsFilters = payload
    },
    addOnServiceFilterCommit (state, payload) {
      state.addOnServiceFilters = payload
    },
    serviceAreaViewsFilterCommit (state, payload) {
      state.serviceAreaViewsFilters = payload
    },
    searchAddOnServiceCommit (state, payload) {
      state.addOnServiceSearch = payload
    },
    jobsFilterCommit (state, payload) {
      state.jobsFilters = payload
    },
    jobTimelineFilterCommit (state, payload) {
      state.jobTimelineFilters = payload
    },
    SET_TOKEN (state, token){
      state.token = token
    },
    LOGIN_USER (state, user){
      state.authStatus = true
      state.user = { ...user }
    },
    LOGOUT_USER(state){
      state.authStatus = ''
      state.token = '' && localStorage.removeItem('apollo-token')
    },
    RESET_STATE (state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    }
  },
  actions: {
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    },
    customerFilterAction (context, payload) {
      context.commit('customerFilterCommit', payload.customerFilters)
    },
    orderFilterAction (context, payload) {
      context.commit('orderFilterCommit', payload.orderFilters)
    },
    searchAction (context, payload) {
      context.commit('searchCommit', payload.customerSearch)
    },
    workshopOrderFilterAction (context, payload) {
      context.commit('workshopOrderFilterCommit', payload.workshopOrderFilters)
    },
    orderCreateAction (context, payload){
      context.commit('createOrderCommit', payload.customerId)
    },
    washSubscriptionFilterAction(context, payload){
      context.commit('washSubscriptionFilterCommit', payload.washSubscriptionFilters)
    },
    washSearchAction(context, payload){
      context.commit('searchWashCommit', payload.washSubscriptionSearch )
    },
    scheduleViewsFilterAction (context, payload) {
      context.commit('scheduleViewsFilterCommit', payload.scheduleViewsFilters)
    },
    serviceEnqFilterAction (context, payload) {
      context.commit('serviceEnqFilterCommit', payload.serviceEnqFilters)
    },
    packageViewFilterAction (context, payload) {
      context.commit('packageViewFilterCommit', payload.packageViewFilters)
    },
    packageTypeViewsFilterAction (context, payload) {
      context.commit('packageTypeViewsFilterCommit', payload.packageTypeViewsFilters)
    },
    addOnServiceFilterAction (context, payload) {
      context.commit('packageTypeViewsFilterCommit', payload.addOnServiceFilters)
    },
    serviceAreaViewsFilterAction (context, payload) {
      context.commit('serviceAreaViewsFilterCommit', payload.serviceAreaViewsFilters)
    },
    addOnServiceSearchAction(context, payload){
      context.commit('searchAddOnServiceCommit', payload.addOnServiceSearch )
    },
    jobsFilterAction (context, payload) {
      context.commit('jobsFilterCommit', payload.jobsFilters)
    },
    jobTimelineFilterAction (context, payload) {
      context.commit('jobTimelineFilterCommit', payload.jobTimelineFilters)
    },
    async login({ commit },{vm, authDetails}){
        try{
     
          await client.mutate({
              mutation: LOGIN_USER,
              fetchPolicy: 'no-cache',
              variables: { ...authDetails },
             
          }).then(data =>{
              if(data.data.tokenCreate.errors.length === 0){
                const token = data.data.tokenCreate.token
                const userDetails = JSON.stringify(data.data.tokenCreate.user)
                commit('SET_TOKEN', token)
                commit('LOGIN_USER', userDetails)
                localStorage.setItem('apollo-token', token)
                localStorage.setItem('user-details', userDetails);
                if(data.data.tokenCreate.user.jobProfile !== null){
                  vm.$router.push({name: 'layout.dashboard'})
                  // if(data.data.tokenCreate.user.jobProfile.type === 'REGION_MANAGER'){
                  //   vm.$router.push({name: 'layout.dashboard'})
                  // }
                  // else{
                  //   vm.$router.push({name: 'order'})
                  // }
                }
                else{
                  vm.$bvToast.toast(`Permission denied! As the user does not belong to any job profile.`, {
                    toaster: "b-toaster-top-center",
                    variant: "danger",
                    solid: true,
                    toastClass: "text-center toast-box toast-style",
                    // noAutoHide: true
                  });
                }
              }else{
                const message = data.data.tokenCreate.errors.map(err => {
                      return err.message;
                  })

                  vm.$bvToast.toast(`${message}`, {
                    toaster: "b-toaster-top-center",
                    variant: "danger",
                    solid: true,
                    toastClass: "text-center toast-box toast-style",
                    // noAutoHide: true
                  });
                
              
              }
          })
       
        }catch(e){
          console.log('pp'+e)
        }
    },
    async logOut ({ commit }) {
      commit('LOGOUT_USER')
      commit('RESET_STATE')
      if (typeof localStorage !== 'undefined') {
        // window.localStorage.clear();
        localStorage.removeItem('apollo-token');
        localStorage.removeItem('user-details');
        // commit('RESET_SET');
        // localStorage.removeItem("orderFilters");
        localStorage.removeItem("jobsFilters");
      }
    }
  },
  getters: {
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    img: state => { return state.img },
    dark: state => { return state.dark },
    isAuthenticated: state => { return !!state.token },
    authStatus: state => state.authStatus,
    user: state => state.user,
    customerFilters: state => { return state.customerFilters },
    customerSearch: state => { return state.customerSearch },
    orderFilters: state => { return state.orderFilters },
    customerId: state => { return state.customerId },
    workshopOrderFilters: state => { return state.workshopOrderFilters },
    washSubscriptionFilters : state => { return state.washSubscriptionFilters },
    washSubscriptionSearch: state => { return state.washSubscriptionSearch },
    scheduleViewsFilters : state => { return state.scheduleViewsFilters },
    serviceEnqFilters: state => { return state.serviceEnqFilters },
    packageViewFilters: state => { return state.packageViewFilters },
    packageTypeViewsFilters : state => { return state.packageTypeViewsFilters },
    addOnServiceFilters : state => { return state.addOnServiceFilters },
    jobsFilters : state => { return state.jobsFilters },
    jobTimelineFilters : state => { return state.jobTimelineFilters },
    serviceAreaViewsFilters : state => { return state.serviceAreaViewsFilters },
  },
  modules: {
  }
})
