import gql from 'graphql-tag'

export const LOGIN_USER = gql`
mutation tokenCreate($email: String! $password: String!){
  tokenCreate(email: $email, password: $password) {
    errors {
      field
      message
    }
    token
    user {
      firstName
      lastName
      phone
      jobProfile {
        type
      }
      wallet {
        balance{
          currency
        }
      }
    }
  }
}
`

export const ADD_CUSTOMER = gql`
mutation userCreate($firstName: String!, $lastName: String!, $phone: String!, $email: String!, $referrer: String!, $notes: String!){
  userCreate(
      input: {firstName: $firstName, lastName: $lastName, phone: $phone, email: $email, referrer: $referrer, notes: $notes}
  ) {
      errors {
      field
      message
  }
  user {
      id
      firstName
      lastName
      phone
      email
      referrer {
          code
      }
  referralCode
  notes
  }
}
}   
`

export const CREATE_CAR = gql`
mutation userCarCreate($classification: CarClassificationEnum, $model: ID!, $year: Int!, $userId: String!, $licensePlate: String, $vin: String, $registrationRenewalAt:Date
  $insuranceRenewalAt:Date,$registrationCardFront: Upload, $registrationCardBack: Upload){
    userCarCreate(
      input: {classification: $classification, model: $model, year: $year, userId: $userId, licensePlate: $licensePlate, vin:$vin, registrationRenewalAt:$registrationRenewalAt
        insuranceRenewalAt:$insuranceRenewalAt, registrationCardFront:$registrationCardFront, registrationCardBack:$registrationCardBack}
    ) {
      errors {
        field
        message
      }
      car {
        id
        classification
        createdAt
        licensePlate
        model {
          make {
            name
          }
          name
        }
        vin
        year
        mileage
        mileageUpdatedAt
        nextServiceMileage
        nextServiceDue
        registrationCard{
          id
          front
          back
          createdAt
          createdBy{
            firstName
            lastName
          }
          frontExtension
          backExtension
          frontFilename
          backFilename
        }
      }
    }
  } 
`

export const UPDATE_CAR = gql`
mutation userCarUpdate($id:ID!, $year:Int!, $licensePlate:String, $vin:String, $mileage:Int, $nextServiceMileage:Int, $nextServiceDue:Date,$registrationRenewalAt:Date
  $insuranceRenewalAt:Date, $registrationCardFront: Upload, $registrationCardBack: Upload){
  userCarUpdate(
    input: {id:$id, year:$year, licensePlate:$licensePlate, vin:$vin, mileage:$mileage, nextServiceMileage:$nextServiceMileage, nextServiceDue:$nextServiceDue, registrationRenewalAt:$registrationRenewalAt
      insuranceRenewalAt:$insuranceRenewalAt, registrationCardFront:$registrationCardFront, registrationCardBack:$registrationCardBack}
  ) {
    errors {
      field
      message
    }
    car {
      id
      classification
      createdAt
      licensePlate
      model {
        make {
          name
        }
        name
      }
      vin
      year
      mileage
      mileageUpdatedAt
      nextServiceMileage
      nextServiceDue
      registrationCard{
        id
        front
        back
        createdAt
        createdBy{
          firstName
          lastName
        }
        frontExtension
        backExtension
        frontFilename
        backFilename
      }
    }
  }
}

`

export const UPDATE_USER = gql`
mutation userUpdate($id:String!, $firstName:String!, $lastName:String!, $notes: String, $email: String, $referrer: String!,) {
  userUpdate(input: {id: $id, firstName: $firstName, lastName: $lastName, notes: $notes, email: $email, referrer: $referrer}) {
    errors {
      field
      message
    }
    user {
      id
      firstName
      lastName
      phone
      email
      referrer {
        code
      }
      referralCode
      notes
    }
  }
}`

export const CREATE_ORDER = gql`
mutation offlineOrderCreate($customer: String!, $car: ID!, $customer_lat: Float!, $customer_lng: Float!, $scheduledAt: DateTime!, $total: Decimal!, $paymentMethod: ID!, $customerNote:String, $staffNote: String, $serviceArea: ID!, $isWalkIn: Boolean, $services: [OfflineOrderServiceInput!]!, $discount: Decimal, $discountName: String, $voucherCode: String, $tags: [ID!]) {
  offlineOrderCreate(
    input: {customer: $customer, car: $car, customerLocation: {lat: $customer_lat, lng: $customer_lng}, scheduledAt: $scheduledAt, total: $total, paymentMethod: $paymentMethod, customerNote: $customerNote, serviceArea: $serviceArea, isWalkIn: $isWalkIn, services: $services, discount: $discount, discountName: $discountName, staffNote: $staffNote, voucherCode: $voucherCode, tags: $tags}
  ) {
    errors {
      field
      message
    }
    order {
      id
      number
      status
      scheduledAt
      customerLocation {
        lat
        lng
      }
      isWalkIn
      car {
        model {
          name
          make {
            name
          }
        }
        year
        classification
        licensePlate
      }
      cancelledAt
      cancelReason
      totalExclTax {
        amount
        currency
      }
      totalInclTax {
        amount
        currency
      }
      tax {
        amount
        currency
      }
      discountName
      discount {
        amount
        currency
      }
      rounding {
        amount
      }
      voucher {
        name
      }
      paymentMethod {
        name
        type
      }
      paymentSource {
        canPayAfter
        canPayNow
        isOnline
        invoice {
          number
          paymentLink
          status
          isDirectCapture
          paymentDetails
          isAuthorised
          isPaid
        }
      }
      lines {
        price {
          amount
          currency
        }
        serviceName
      }
      customer {
        firstName
        lastName
        phone
        email
      }
      staffNote
      tags {
        id
        name
      }
    }
  }
}
`

export const JOB_ASSIGN = gql`
mutation jobAssign($jobId:ID!, $userId:ID!) {
  jobAssign(jobId: $jobId, userId: $userId) {
    errors {
      field
      message
    }
    job {
      id
      type
      status
      assignedTo {
        id
        firstName
        lastName
        phone
      }
    }
  }
}
`

export const ADD_STRIPE_INVOICE = gql`
mutation invoiceStripeCreate($customerId: String, $number: String!, $notes: String, $directCapture: Boolean!, $amount: Decimal!) {
  invoiceStripeCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, directCapture: $directCapture, customerId: $customerId}
  ){
    errors {
      field
      message
    }
    invoice {
      id
      paymentStatus
      paymentLink
    }
  }
}
`

export const UPDATE_STRIPE_INVOICE = gql`
mutation invoiceStripeUpdate($invoiceID: ID!, $action: StripeInvoiceActionEnum, $notes: String) {
  invoiceStripeUpdate(id: $invoiceID, notes: $notes, action: $action) {
    errors {
      field
      message
    }
    invoice {
      id
      number
      amountAmount
      paymentStatus
      status
      isPaid
      paymentDetails
      directCapture
      authorisedAt
      capturedAt
      customer {
        id
        firstName
        lastName
      }
      notes
      canCapture
      canCancel
      paymentLink
      currency
    }
  }
}
`

export const ADD_CASH_FREE_INVOICE = gql`
mutation invoiceCashfreeCreate($number: String!, $notes: String, $amount: Decimal!, $customerId: String!) {
  invoiceCashfreeCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, customerId: $customerId}) 
    {
      errors {
        field
        message
      }
      invoice {
        id
        paymentStatus
        paymentLink
      }
    }
  }
`

export const UPDATE_CASH_FREE_INVOICE = gql`
mutation invoiceCashFreeUpdate($invoiceID: ID!, $action: CashfreeInvoiceActionEnum, $notes: String) {
  invoiceCashfreeUpdate(id: $invoiceID, notes: $notes, action: $action) {
    errors {
      field
      message
    }
    invoice {
      id
      number
      amountAmount
      paymentStatus
      status
      isPaid
      paymentDetails
      paidAt
      customer {
        id
        firstName
        lastName
      }
      notes
      canCancel
      paymentLink
      currency
    }
  }
}
`

export const ORDER_UPDATE = gql`
mutation orderUpdate($orderId:ID!, $action:OrderActionEnum, $scheduledAt: DateTime, $staffNote:String, $assignedToId:ID, $comment:String,  
  $approvedQuotation: Upload, $isWhisper: Boolean, $isQuoteRequired: Boolean, $workshopId: ID, $workshopExpectedCompletionAt: DateTime, 
  $paymentMethodId: ID, $workshopAutoRejectAt: DateTime, $services: [OrderLineInput!], $total: Decimal, $discountName: String, $discount: Decimal,
  $additionalPaymentMethodId: ID, $isInvoiced: Boolean, $erpInvoiceNumber: String, $tags: [ID!]
  ) {
  orderUpdate(
    orderId: $orderId,
    input: {action: $action, staffNote: $staffNote, assignedToId: $assignedToId, scheduledAt: $scheduledAt, workshopId:$workshopId, 
      workshopExpectedCompletionAt: $workshopExpectedCompletionAt,comment: $comment, approvedQuotation: $approvedQuotation, isWhisper: $isWhisper, 
      isQuoteRequired: $isQuoteRequired, paymentMethodId: $paymentMethodId, workshopAutoRejectAt: $workshopAutoRejectAt, total: $total, 
      discountName: $discountName, discount: $discount, services: $services, additionalPaymentMethodId: $additionalPaymentMethodId,
      isInvoiced: $isInvoiced, erpInvoiceNumber: $erpInvoiceNumber, tags: $tags}
  ) {
    errors {
      field
      message
    }
    order {
      id
      status
      isInvoiced
      isWalkIn
      erpInvoiceNumber
      scheduledAt
      paymentMethod {
        id
        name
      }
      canUpdate
      canCancel
      canFulfill
      canInvoice
      paymentSource {
        isOnline
        canPayNow
        canPayAfter
        invoice {
          isPaid
          paymentDetails
          status
        }
      }
      isQuoteRequired
      staffNote
      workshop {
        address
        name
      }
      workshopAcceptedBy{
        firstName
        lastName
      }
      workshopAssignedAt
      workshopExpectedCompletionAt
      workshopAutoRejectAt
      approvedQuotation
      comments{
        comment
        isWhisper
        createdAt
        createdBy{
          firstName
          lastName
        }
      }
      confirmedBy{
        firstName
        lastName
      }
      confirmedAt
      assignedTo {
        id
        firstName
        lastName
      }
      customerLocation {
        lat
        lng
      }
      tags {
        id
        name
      }
    }
  }
}
`

export const ORDER_SAVE = gql`
mutation orderUpdate($orderId:ID!, $lat:Float!, $lng:Float!, $scheduledAt: DateTime, $staffNote:String, $action:OrderActionEnum, 
  $workshopId: ID, $isQuoteRequired: Boolean, $paymentMethodId: ID, $approvedQuotation: Upload){
  orderUpdate(
    orderId: $orderId
    input: {customerLocation: {lat: $lat, lng: $lng}, scheduledAt: $scheduledAt, staffNote: $staffNote, action: $action, workshopId: $workshopId, isQuoteRequired: $isQuoteRequired, paymentMethodId: $paymentMethodId, approvedQuotation: $approvedQuotation}
  ) {
    errors {
      field
      message
    }
    order {
      id
      status
      isInvoiced
      isWalkIn
      scheduledAt
      paymentMethod {
        id
        name
      }
      paymentSource {
        isOnline
        canPayNow
        canPayAfter
        invoice {
          isPaid
          paymentDetails
          status
        }
      }
      assignedTo {
        id
        firstName
        lastName
      }
      customerLocation {
        lat
        lng
      }
    }
  }
}
`

export const ORDER_UPLOAD_ATTACHMENTS = gql`
mutation orderUploadAttachments($orderId:ID!, $files: [Upload!]!, $titles: [String!], $isImage: Boolean!, $isBefore: Boolean, $isApproved: Boolean!){
  orderUploadAttachments(input: {orderId: $orderId, files: $files, titles: $titles, isImage: $isImage, isBefore: $isBefore, isApproved: $isApproved}){
    errors {
      field
      message
    }
    order {
      id
      status
      isInvoiced
      isWalkIn
      scheduledAt
      paymentMethod {
        id
        name
      }
      paymentSource {
        isOnline
        canPayNow
        canPayAfter
        invoice {
          isPaid
          paymentDetails
          status
        }
      }
      assignedTo {
        id
        firstName
        lastName
      }
      customerLocation {
        lat
        lng
      }
    }

  }
}
`

export const ORDER_PART_QUOTATIONS_CREATE = gql`
mutation DashboardPartQuotationsCreate(
  $orderId: ID!,
  $isFullyQuoted: Boolean,
  $input: [DashboardPartQuotationCreateInput!]!
) {
  dashboardPartQuotationsCreate(
    input: $input, orderId: $orderId, isFullyQuoted: $isFullyQuoted
  ) {
    partQuotations {
      id
      partName
      quantity
      quality
      status
      actedAt
      brand
      partNumber
      availability
      createdAt
      priceSupplier {
        amount
        currency
      }
      priceMysyara {
        amount
        currency
      }
      createdBy {
        firstName
        lastName
      }
      partMarginRule {
        percentage
      }
      unitPriceSupplier {
        amount
        currency
      }
      unitPriceMysyara {
        amount
        currency
      }
    }
    errors {
      field
      message
    }
  }
}
`

export const ORDER_PART_QUOTATION_UPDATE = gql`
mutation DashboardPartQuotationUpdate($priceMysyara: Decimal!, $id: ID!) {
  dashboardPartQuotationUpdate(priceMysyara: $priceMysyara, id: $id) {
    partQuotation {
      id
      priceMysyara {
        amount
        currency
      }
      unitPriceMysyara {
        amount
        currency
      }
    }
    errors {
      field
      message
    }
  }
}
`

export const INVOICE_CASH_UPDATE = gql`
mutation invoiceCashUpdate($invoiceID: ID!, $action: CashInvoiceActionEnum, $notes:String) {
  invoiceCashUpdate(id: $invoiceID, action: $action, notes: $notes) {
    errors {
      field
      message
    }
    invoice {
      id
      paymentStatus
      status
      isPaid
      notes
    }
  }
}
`

export const INVOICE_CYBER_SOURCE_UPDATE = gql`
mutation invoiceCybersourceUpdate($invoiceID: ID!, $action: CyberSourceInvoiceActionEnum, $notes:String) {
  invoiceCybersourceUpdate(id: $invoiceID, action: $action, notes: $notes) {
    errors {
      field
      message
    }
    invoice {
      id
      paymentStatus
      status
      isPaid
      notes
    }
  }
}
`

export const ADD_CYBER_SOURCE_INVOICE = gql`
mutation invoiceCybersourceCreate($customerId: String, $number: String!, $notes: String, $directCapture: Boolean!, $amount: Decimal!) {
  invoiceCybersourceCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, directCapture: $directCapture, customerId: $customerId}
  ){
    errors {
      field
      message
    }
    invoice {
      id
      paymentStatus
      paymentLink
    }
  }
}
`


export const UPDATE_JOB_SCHEDULED_AT = gql`
mutation dashboardJobUpdate($jobId: ID! $scheduledAt: DateTime, $unschedule: Boolean, $notes: String, $hoursRequired: Decimal){
  dashboardJobUpdate(
    jobId: $jobId, scheduledAt: $scheduledAt, unschedule: $unschedule, notes: $notes, hoursRequired: $hoursRequired
  ){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
      notes
    }
  }
}
`

export const UPDATE_JOB_FROM_LOCATION = gql`
mutation dashboardJobUpdate($jobId: ID!  $fromlat: Float!, $fromlng: Float!){
  dashboardJobUpdate(
    jobId: $jobId, fromLocation: {lat: $fromlat, lng: $fromlng}, 
  ){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
    }
  }
}
`

export const UPDATE_JOB_TO_LOCATION = gql`
mutation dashboardJobUpdate($jobId: ID!  $tolat: Float!, $tolng: Float!){
  dashboardJobUpdate(
    jobId: $jobId, toLocation: {lat: $tolat, lng: $tolng}, 
  ){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
    }
  }
}
`

export const UPDATE_JOB_SCHEDULED_AT_ONLY = gql`
mutation dashboardJobUpdate($jobId: ID! $scheduledAt: DateTime){
  dashboardJobUpdate(
    jobId: $jobId, scheduledAt: $scheduledAt
  ){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
      notes
    }
  }
}
`

export const UPDATE_JOB_LOCATION_SCHEDULED_AT = gql`
mutation dashboardJobUpdate($jobId: ID! $scheduledAt: DateTime, $fromlat: Float!, $fromlng: Float!, $tolat: Float!, $tolng: Float!){
  dashboardJobUpdate(
    jobId: $jobId, scheduledAt: $scheduledAt, fromLocation: {lat: $fromlat, lng: $fromlng}, toLocation: {lat: $tolat, lng: $tolng}
  ){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
    }
  }
}
`

export const DASHBOARD_JOB_CREATED = gql`
mutation dashboardJobCreate($type: JobTypeEnum!, $orderId: ID!){
  dashboardJobCreate(type: $type, orderId: $orderId){
    errors {
      field
      message
    }
    job{
      id
      scheduledAt
      status
      hoursRequired
      hoursTaken
      assignedTo{
        firstName
        lastName
      }
      fromLocation{
        lat
        lng
      }
      toLocation{
        lat
        lng
      }
    }
  }
}`

export const WASH_SUBSCRIPTION_CREATE = gql`
mutation offlineWashSubscriptionCreate(
  $carId: ID!, 
  $lat: Float!,
  $lng: Float!,
  $packageId: ID!, 
  $customerAddressId: ID!, 
  $parkingSlot: String!, 
  $scheduledSlots: [ID!]!, 
  $amount:  Decimal!, 
  $currency: String!, 
  $userId: String!,
  $discount: Decimal!,
  $paymentMethod: ID,
  $notes: String,
  $isCash: Boolean,
  $address: String!,
  $chargeAutomatically: Boolean,
  $voucherCode: String
){
  offlineWashSubscriptionCreate(input: {
    carId: $carId,
    location: {lat: $lat, lng: $lng},
    packageId: $packageId, 
    customerAddressId: $customerAddressId, 
    parkingSlot: $parkingSlot, 
    scheduledSlots: $scheduledSlots, 
    total: {amount: $amount, currency: $currency}, 
    discount: {amount: $discount, currency: $currency},
    userId: $userId,
    paymentMethod: $paymentMethod,
    notes: $notes,
    isCash: $isCash,
    address: $address,
    chargeAutomatically: $chargeAutomatically,
    voucherCode: $voucherCode
  }){
    errors{
      field
      message
    }
    washSubscription{
      id
      building{
        id
        company{
          legalName
          registeredAddress
        }
        name
        isArea
        location{
          lat
          lng
        }
        address
        contactPerson{
          firstName
          lastName
          phone
          email
        }
        shortCode
      }
      package{
        id
        type{
          name
          id
          slug
          weeklyWashes
          isActive
          rank
          description
        }
        intervalType
        numIntervals
      }
      customer{
        firstName
        lastName
        email
        phone
      }
      car{
        id
        model{
          id
          name
          make{
            name
            id
          }
        }
        year
        licensePlate
        vin
      }
      total{
        amount
        currency
      }
      tax{
        amount
        currency
      }        
      activatedAt
      pausedAt
      createdAt
      updatedAt
      validUpto
      unpausedAt
      paymentUrl
      completedAt
      cancelledAt
      lastReleaseAt
      nextReleaseAt
      status
      responseUrl
    }
  }
}`

export const CUSTOMER_ADDRESS_CREATE = gql`
mutation userAddressCreate($userId: String!, $lat: Float!, $lng: Float!, $address: String!, $label: String!){
  userAddressCreate(input: {userId: $userId, location: {lat: $lat, lng: $lng}, address: $address, label: $label}){
    errors {
      field
      message
    }
    address {
      id
      location {
        lat
        lng
      }
      address
      label
      createdAt
    }
  }
}
`

export const CUSTOMER_CREDIT_GRANT = gql`
mutation userCreditsGrant($userId: String!, $amount: Decimal!, $type: WalletTransactionTypeEnum, $description: String! ){
  userCreditsGrant(input:{userId:$userId,amount:$amount,type:$type,description:$description}){
    wallet{
      balance{
        amount
        currency
      }
      expiryDate
    }
    errors{
      field
      message
    }
  }
}`

export const DASHBOARD_WASH_SUBSCRIPTION_UPDATE = gql`
mutation offlineWashSubscriptionUpdate(
  $washSubscriptionId: ID!, 
  $assignedToId: ID,
  $scheduledSlots: [ID!],
  $comment: String,
  $action: WashSubscriptionActionEnum,
  $pauseReason: String,
  $cancelReason: String,
){
  offlineWashSubscriptionUpdate(input: {
    washSubscriptionId: $washSubscriptionId, 
    assignedToId: $assignedToId,
    scheduledSlots: $scheduledSlots,
    comment: $comment,
    action: $action,
    pauseReason: $pauseReason,
    cancelReason: $cancelReason,
  }){
    errors{
      field
      message
    }
    washSubscription{
      id
      assignedTo{
        firstName
        lastName
      }
      building{
        id
        company{
          legalName
          registeredAddress
        }
        name
        isArea
        location{
          lat
          lng
        }
        address
        contactPerson{
          firstName
          lastName
          phone
          email
        }
        shortCode
      }
      package{
        id
        type{
          name
          id
          slug
          weeklyWashes
          isActive
          rank
          description
        }
        intervalType
        numIntervals
      }
      customer{
        firstName
        lastName
        email
        phone
      }
      car{
        id
        model{
          id
          name
          make{
            name
            id
          }
        }
        year
        licensePlate
        vin
      }
      total{
        amount
        currency
      }
      tax{
        amount
        currency
      }        
      activatedAt
      pausedAt
      createdAt
      updatedAt
      validUpto
      unpausedAt
      paymentUrl
      completedAt
      cancelledAt
      lastReleaseAt
      nextReleaseAt
      status
      responseUrl
      cancelReason
      pauseReason
    }
  }
}`

export const ADD_CHECKOUT_INVOICE = gql`
mutation invoiceCheckoutCreate($customerId: String, $number: String!, $notes: String, $directCapture: Boolean!, $amount: Decimal!) {
  invoiceCheckoutCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, directCapture: $directCapture, customerId: $customerId}
  ){
    errors {
      field
      message
    }
    invoice {
      id
      paymentStatus
      paymentUrl
    }
  }
}
`

export const UPDATE_CHECKOUT_INVOICE = gql`
mutation invoiceCheckoutUpdate($invoiceID: ID!, $action: CheckoutInvoiceActionEnum, $notes: String) {
  invoiceCheckoutUpdate(id: $invoiceID, notes: $notes, action: $action) {
    errors {
      field
      message
    }
    invoice {
      id
      number
      amountAmount
      paymentStatus
      status
      isPaid
      paymentDetails
      directCapture
      authorisedAt
      capturedAt
      customer {
        id
        firstName
        lastName
      }
      notes
      canCapture
      canCancel
      paymentUrl
      currency
    }
  }
}
`

export const ADD_UPLOAD_ESTIMATE = gql`
mutation dashboardServiceUploadEstimate($customer: String!,$carId: ID!, $lat:Float!,$lng: Float!, $categoryId: ID!, $image: Upload!, $customerNote: String) {
  dashboardServiceUploadEstimate(
    input: {customer: $customer, carId: $carId, location: {lat: $lat, lng: $lng}, categoryId: $categoryId, competitorEstimate: $image, customerNote: $customerNote}
  ){
    errors{
      field
      message
    }
    uploadEstimate{
      id
      isFullyQuoted
      customer{
        firstName
        lastName
        phone
        email
      }
      customerLocation{
        lat
        lng
      }
      car{
        id
        model{
          name
          make{
            name
          }
        }
      }
      category{
        name
      }
      staffNote
      customerNote
      competitorEstimate
      ourEstimate      
      competitorPrice{
        currency
        amount
      }
      ourPrice{
        currency
        amount
      }
      quotedAt
      createdAt
    }
  }
}
`

export const UPDATE_UPLOAD_ESTIMATE = gql`
mutation dashboardUploadEstimateUpdate($id: ID!, $ourPriceAmount: Decimal!,$competitorPriceAmount: Decimal!, $isFullyQuoted: Boolean, $image: Upload, $staffNote: String) {
  dashboardUploadEstimateUpdate(
    input: {id: $id, ourPriceAmount: $ourPriceAmount, competitorPriceAmount: $competitorPriceAmount, isFullyQuoted: $isFullyQuoted, ourEstimate: $image, staffNote: $staffNote}
  ){
    errors{
      field
      message
    }
    uploadEstimate{
      id
      isFullyQuoted
      customer{
        firstName
        lastName
        phone
        email
      }
      customerLocation{
        lat
        lng
      }
      car{
        id
        model{
          name
          make{
            name
          }
        }
      }
      category{
        name
      }
      staffNote
      customerNote
      competitorEstimate
      ourEstimate      
      competitorPrice{
        currency
        amount
      }
      ourPrice{
        currency
        amount
      }
      quotedAt
      createdAt
      updatedAt
    }
  }
}
`

export const UPDATE_SCHEDULED_VIEW = gql`
mutation dashboardScheduleViewUpdate($id:ID!, $notes:String, $status:LeadStatusEnum){
  dashboardScheduleViewUpdate(id:$id, notes:$notes, status:$status){
    errors{
      message
      field
    }
    scheduleView{
      customer{
        firstName
        lastName
        email
        phone
      }
      serviceArea{
        area
        category{
          id
          name
          description
        }
      }
      isNotified
      isConverted
      nextSlotAvailable
      viewedAt
      notes
      status
      id
    }
  }
}`

export const ADD_MONEYHASH_INVOICE = gql`
mutation invoiceMoneyhashCreate($customerId: String, $number: String!, $notes: String, $directCapture: Boolean!, $amount: Decimal!) {
  invoiceMoneyhashCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, directCapture: $directCapture, customerId: $customerId}
  ){
    errors {
      field
      message
    }
    invoice{
      id
      number
      amountAmount
      customer{
        firstName
        lastName
        phone
        email
      }
      directCapture
      status
      paymentLink
      paymentStatus
      notes
      status
    }
  }
}
`

export const UPDATE_MONEYHASH_INVOICE = gql`
mutation invoiceMoneyhashUpdate($id: ID!, $notes: String, $action: MoneyHashInvoiceActionEnum){
  invoiceMoneyhashUpdate(id:$id, action:$action, notes:$notes){
     invoice{
      number
      amountAmount
      customer{
        firstName
        lastName
        phone
        email
      }
      directCapture
      status
      paymentLink
      paymentStatus
      notes
      status
    }
    errors{
      message
      field
    }
  }
}
`

export const DASHBOARD_SCHEDULE_BLOCK_CREATION = gql`
  mutation dashboardScheduleBlockCreate($serviceArea: ID!, $blockFrom: DateTime!, $blockTo: DateTime!, $comment: String){
    dashboardScheduleBlockCreate(input : {
      serviceArea: $serviceArea,
      blockFrom: $blockFrom,
      blockTo: $blockTo,
      comment: $comment
    }){
      errors{
        field
        message
      }
      scheduleBlock{
        serviceSchedule{
          schedule
        }
        scheduleV2{
          id
          name
        }
        blockFrom
        blockTo
        comment
        isSystemGenerated
        isActive
        id
      }
    }
  }
`

export const DASHBOARD_SCHEDULE_BLOCK_UPDATE = gql`
  mutation dashboardScheduleBlockUpdate($id: ID!, $isActive: Boolean, $comment: String){
    dashboardScheduleBlockUpdate(
      id: $id,
      comment: $comment,
      isActive: $isActive
    ){
      errors{
        field
        message
      }
      scheduleBlock{
        serviceSchedule{
          schedule
        }
        scheduleV2{
          id
          name
        }
        blockFrom
        blockTo
        comment
        isSystemGenerated
        isActive
        id
      }
    }
  }
`

export const ADD_PAYTABS_INVOICE = gql`
mutation invoicePaytabsCreate($customerId: String, $number: String!, $notes: String, $directCapture: Boolean!, $amount: Decimal!) {
  invoicePaytabsCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, directCapture: $directCapture, customerId: $customerId}
  ){
    errors{
      field
      message
    }
    invoice{
      id
      number
      amountAmount
      paymentStatus
      paymentLink
      notes
    }
  }
}
`

export const UPDATE_PAYTABS_INVOICE = gql`
mutation invoicePaytabsUpdate($id: ID!, $notes: String, $action: PaytabsInvoiceActionEnum){
  invoicePaytabsUpdate(id:$id, notes:$notes, action:$action){
    errors{
      field
      message
    }
    invoice{
      id
      number
      amountAmount
      paymentStatus
      paymentLink
      notes
    }
  }
}
`

export const DASHBOARD_SUBSCRIPTION_UPDATE = gql`
  mutation dashboardSubscriptionUpdate($id: ID!, $notes: String, $comment: String){
    dashboardSubscriptionUpdate(
      id: $id,
      notes: $notes,
      comment: $comment,
    ){
      errors{
        field
        message
      }
      subscription{
        id
        number
        notes
        comments{
          comment
          createdAt
          createdBy{
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const UPDATE_PACKAGE_VIEW = gql`
mutation dashboardPackageViewUpdate($id:ID!, $notes:String, $status: LeadStatusEnum){
  dashboardPackageViewUpdate(id:$id, notes:$notes, status:$status){
    errors{
      message
      field
    }
    packageView{
      customer{
        firstName
        phone
        lastName
        email
      }
      id
      car{
        model{
          name
          make{
            name
          }
        }
      }
      location{
        lat
        lng
      }
      convertedAt
      viewedAt
      notes
      status
      building{
        id
        name
      }
    }
  }
}`

export const UPDATE_SERVICE_ENQUIRY = gql`
mutation dashboardServiceEnquiryUpdate($id:ID!, $notes:String, $status: LeadStatusEnum){
  dashboardServiceEnquiryUpdate(id:$id, notes:$notes, status:$status){
    errors{
      message
      field
    }
    serviceEnquiry{
      id
    customer{
      firstName
      lastName
      email
      phone
    }
    car{
      model{
        name
        make{
          name
        }
      }
    }
    location{
      lat
      lng
    }
    serviceArea{
      area
      category{
        name
      }
    }
    enquiredAt
    notes
    status
    }
  }
}`

export const CREATE_DND_FOR_USER = gql`
mutation dashboardTemporaryDndCreate($userId: String!, $days:Int!){
  dashboardTemporaryDndCreate(input:{
    userId:$userId, days:$days
  }){
    errors{
      message
      field
    }
    temporaryDnd{
      user{
        firstName
        lastName
      }
      createdBy{
        firstName
        lastName
      }
      id
      createdAt
      until
    }
  }
}
`

export const UPDATE_PACKAGE_TYPE_VIEW_NOTES = gql`
mutation dashboardPackageTypeViewUpdate($id:ID!, $notes:String, $status: LeadStatusEnum){
  dashboardPackageTypeViewUpdate(id:$id, notes:$notes, status: $status){
    errors{
      message
      field
    }
    packageTypeView{
      id
      customer{
        firstName
        lastName
        email
        phone
      }
      car{
        model{
          name
          make{
            name
          }
        }
      }
      packageType{
        name
      }
      viewedAt
      notes
      status
    }
  }
}`

export const ADD_ON_SERVICE_CREATE = gql`
mutation offlineAddOnServiceCreate($subscriptionId: ID!, $serviceIds: [ID!]!, $amount:  Decimal!, 
  $currency: String!, $voucherCode: String){
  offlineAddOnServiceCreate(input:{
    subscriptionId:$subscriptionId,
    serviceIds:$serviceIds,
    total:{ currency: $currency, amount: $amount},
    voucherCode:$voucherCode
  }){
    errors{
      message
    }
    addOnServices{
      id
      number
    }
  }
}
`


export const DASHBOARD_REFERRAL_GET_OR_CREATE_MUTATION = gql`
    mutation dashboardReferralGetOrCreate($userId: String!){
        dashboardReferralGetOrCreate(userId: $userId){
            errors {
                field
                message
            }
            referral {
                code
                referrer
                createdAt
            }
            voucher {
                code
                name
                description
            }
            description
            shareDescription
            created
        }
    }
`

export const UPDATE_SERVICE_AREA_VIEW = gql`
mutation dashboardServiceAreaViewUpdate($id:ID!, $notes:String, $status: LeadStatusEnum){
  dashboardServiceAreaViewUpdate(id:$id, notes:$notes, status:$status){
    errors{
      message
      field
    }
    serviceAreaView{
      id
      customer{
        firstName
        lastName
        email
        phone
      }
      viewedAt
      notes
      status
    }
  }
}`


export const ADD_TABBY_INVOICE = gql`
mutation invoiceTabbyCreate($customerId: String, $number: String!, $notes: String,$amount: Decimal!) {
  invoiceTabbyCreate(
    input: {number: $number, amountAmount: $amount, notes: $notes, customerId: $customerId}
  ){
    errors {
      field
      message
    }
    invoice{
      id
      number
      amountAmount
      paymentStatus
      paymentLink
      notes
    }
  }
}
`


export const UPDATE_TABBY_INVOICE = gql`
mutation invoiceTabbyUpdate($invoiceID: ID!, $notes: String, $action: InvoiceActionEnum){
  invoiceTabbyUpdate(id:$invoiceID, notes:$notes, action:$action){
    errors{
      field
      message
    }
    invoice{
      id
      number
      amountAmount
      paymentStatus
      paymentLink
      notes
    }
  }
}
`

export const DASHBOARD_GENERATE_PART_QUOTATIONS_PDF = gql`
mutation dashboardGeneratePartQuotationsPdf(
  $orderId: ID!, 
  $requiredIds: [ID!]!, 
  $optionalIds: [ID!]!, 
  $notes: String,
  $discountAmount: Decimal!,
  $currency: String!,
  $labourAmount: Decimal!
){
  dashboardGeneratePartQuotationsPdf(
    input: {
      orderId: $orderId
      requiredIds: $requiredIds,
      optionalIds: $optionalIds,
      notes: $notes,
      discount: {amount: $discountAmount, currency: $currency}
      labourPrice: {amount: $labourAmount, currency: $currency}
    }){
    errors{
      field
      message
    }
    partQuotationPdf
  }
}`