<template>
  <router-view/>
</template>
<script>
export default {
  name: 'App',
  components: {
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import "./assets/scss/backend.scss";
</style>
